import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Url from "../global";
import "./DataPersonList.scss";
import * as FaIcons from "react-icons/fa";

const DataPersonList = () => {
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [Nama, setNama] = useState("");
  const [NIK, setNIK] = useState("");
  const [NRY, setNRY] = useState("");
  const [Telpon, setTelpon] = useState("");
  const [Gender, setGender] = useState("");
  const [Unit, setUnit] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [Alamat, setAlamat] = useState("");
  const [Email, setEmail] = useState("");
  const [Jabatan, setJabatan] = useState("");
  const [NPWP, setNPWP] = useState("");
  const [BPJS, setBPJS] = useState("");
  const [BPJSTK, setBPJSTK] = useState("");
  const [PendidikanTerakhir, setPendidikanTerakhir] = useState("");
  const [JumlahJamKerja, setJumlahJamKerja] = useState("");
  const [Deskripsi, setDeskripsi] = useState("");
  const [jenisKaryawan, setJenisKaryawan] = useState("");
  const [TanggalLahir, setTanggalLahir] = useState(new Date());
  const [TanggalMasuk, setTanggalMasuk] = useState(new Date());
  const [TanggalMasaKontrak, setTanggalMasaKontrak] = useState(new Date());
  const [NamaAyah, setNamaAyah] = useState("");
  const [NamaIbu, setNamaIbu] = useState("");
  const { pegawai } = useSelector((state) => state.auth);
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [telegram, setTelegram] = useState("");
  const [visible, setVisible] = useState(false);

  const Icon = visible ? (
    <FaIcons.FaEyeSlash />
  ) : (
    <FaIcons.FaEye onClick={() => setVisible((visiblity) => !visiblity)} />
  );
  const InputType = visible ? "text" : "password";

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const getPegawaiById = async () => {
      try {
        const response = await axios.get(`${Url}/pegawai/${pegawai.uuid}`, {
          credentials: "include",
        });
        setNama(response.data.strNama);
        setNIK(response.data.strNIK);
        setEmail(response.data.strEmail);
        setNRY(response.data.strNRY);
        setUnit(response.data.strUnit);
        setGender(response.data.strGender);
        setTelpon(response.data.strTelpon);
        setTempatLahir(response.data.strTempatLahir);
        setNamaIbu(response.data.strNamaIbu);
        setNamaAyah(response.data.strNamaAyah);
        setAlamat(response.data.strAlamat);
        setTanggalLahir(response.data.dateTanggalLahir);
        setTanggalMasaKontrak(response.data.dateMasaKontrak);
        setJabatan(response.data.strJabatan);
        setNPWP(response.data.strNPWP);
        setBPJS(response.data.strBPJS);
        setBPJSTK(response.data.strBPJSTK);
        setPendidikanTerakhir(response.data.strPendidikanTerakhir);
        setTanggalMasuk(response.data.dateTanggalMasuk);
        setJumlahJamKerja(response.data.strJumlahJamKerja);
        setDeskripsi(response.data.strDeskripsi);
        setTelegram(response.data.strTelegram);
        setInstagram(response.data.strInstagram);
        setFacebook(response.data.strFacebook);
        setTiktok(response.data.strTiktok);
        setJenisKaryawan(response.data.strJenisKaryawan);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getPegawaiById();
  }, [pegawai.uuid]);

  const updatePegawaiFull = async (e) => {
    //e.preventDefault();
    try {
      await axios.patch(
        `${Url}/pegawai/full/${pegawai.uuid}`,
        {
          strNama: Nama,
          strNIK: NIK,
          strNRY: NRY,
          strTelpon: Telpon,
          strUnit: Unit,
          strGender: Gender,
          strTempatLahir: tempatLahir,
          strAlamat: Alamat,
          strEmail: Email,
          strJabatan: Jabatan,
          strNPWP: NPWP,
          strBPJS: BPJS,
          strBPJSTK: BPJSTK,
          strPendidikanTerakhir: PendidikanTerakhir,
          strJumlahJamKerja: JumlahJamKerja,
          strDeskripsi: Deskripsi,
          dateTanggalLahir: TanggalLahir,
          dateTanggalMasuk: TanggalMasuk,
          dateMasaKontrak: TanggalMasaKontrak,
          strNamaAyah: NamaAyah,
          strNamaIbu: NamaIbu,
          strTelegram: telegram,
          strFacebook: facebook,
          strTiktok: tiktok,
          strInstagram: instagram,
          strJenisKaryawan: jenisKaryawan,
          strPassword: password,
          confPassword: confPassword,
        },
        { credentials: "include" }
      );
      navigate("/dashboard");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form className="box" onSubmit={handleSubmit(updatePegawaiFull)}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">
                  Nama Lengkap Pegawai beserta Gelar
                </label>
                <div className="control">
                  <input
                    type="text"
                    // className="input"
                    // disabled
                    className={`input ${errors.Nama && "invalid"}`}
                    {...register("Nama", {

                      minLength: {
                        value: 3,
                        message: "minimal 3 karakter",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("Nama");
                    }}
                    placeholder="Aturan Penulisan Wajib Huruf Depan Besar ex : Kurniawan Dedi, S.Pd"
                    value={Nama}
                    onChange={(e) => setNama(e.target.value)}
                  />
                  {errors.Nama && (
                    <small className="allValidasiError">
                      {errors.Nama.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">NIK</label>
                <div className="control">
                  <input
                    type="number"
                    // className="input"
                    // disabled
                    className={`input ${errors.NIK && "invalid"}`}
                    {...register("NIK", {
                      required: {
                        // value: true,
                        message: "NIK Wajib Di isi!",
                      },
                      minLength: {
                        value: 16,
                        message: "Harus 16 length karakter",
                      },
                      maxLength: {
                        value: 16,
                        message: "Harus 16 length karakter",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("NIK");
                    }}
                    placeholder="Nomor Induk Kependudukan"
                    value={NIK}
                    onChange={(e) => setNIK(e.target.value)}
                  />
                  {errors.NIK && (
                    <small className="allValidasiError">
                      {errors.NIK.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">
                  NRY (*diisi sesuai format kode NRY)
                </label>
                <div className="control">
                  <input
                    type="text"
                    className={`input ${errors.NRY && "invalid"}`}
                    {...register("NRY", {
                      required: {
                        value: true,
                        message: "NRY Wajib Di isi!",
                      },
                    })}
                    placeholder="NRY"
                    defaultValue={NRY} // Nilai awal
                    value={NRY} // Sinkron dengan state
                    onChange={(e) => {
                      setNRY(e.target.value); // Perbarui state
                      trigger("NRY"); // Validasi ulang
                    }}
                  />
                  {errors.NRY && (
                    <small className="allValidasiError">
                      {errors.NRY.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Nomor HP</label>
                <div className="control">
                  {/* validasi 16 Nomor NRY */}
                  <input
                    type="number"
                    // className="input"
                    // disabled
                    className={`input ${errors.Telpon && "invalid"}`}
                    {...register("Telpon", {
                      required: {
                        value: true,
                        message: "Nomor HP Wajib Di isi!",
                      },
                      minLength: {
                        value: 8,
                        message: "minimal 8 length karakter",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("Telpon");
                    }}
                    placeholder="Nomor HP"
                    value={Telpon}
                    onChange={(e) => setTelpon(e.target.value)}
                  />
                  {errors.Telpon && (
                    <small className="allValidasiError">
                      {errors.Telpon.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Gender</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      {...register("Gender", {
                        required: {
                          value: true,
                          message: "Gender Wajib Di isi!",
                        },
                      })}
                      className="dropdown"
                      value={Gender}
                      onChange={(e) => setGender(e.target.value)}
                      required
                    // disabled
                    >
                      <option>------Pilih------</option>
                      <option value="Laki - Laki">Laki - Laki</option>
                      <option value="Perempuan">Perempuan</option>
                    </select>
                    {errors.Gender && (
                      <small className="allValidasiError">
                        {errors.Gender.message}
                      </small>
                    )}
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Jenis Pegawai</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      {...register("jenisKaryawan", {
                        required: {
                          value: true,
                          message: "Jenis Karyawan wajib diisi!",
                        },
                      })}
                      // disabled
                      className="dropdown"
                      value={jenisKaryawan}
                      onChange={(e) => setJenisKaryawan(e.target.value)}
                      required

                    >
                      <option>------Pilih------</option>
                      <option value="Guru">Guru</option>
                      <option value="Karyawan">Karyawan</option>
                    </select>
                    {errors.jenisKaryawan && (
                      <small className="allValidasiError">
                        {errors.jenisKaryawan.message}
                      </small>
                    )}
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    // disabled
                    type="mail"
                    className="input"
                    placeholder="Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Tanggal Lahir</label>
                <div className="control">
                  <Form.Group controlId="duedate" className="dtp">
                    <Form.Control
                      type="date"
                      className={`duedate ${errors.tanggalLahir && "invalid"}`}
                      {...register("tanggalLahir", {
                        required: {
                          value: true,
                          message: "Pilih Sesuai Tanggal Lahir!",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("tanggalLahir");
                      }}
                      // disabled
                      // className="duedate"
                      placeholder="Tanggal Lahir"
                      value={TanggalLahir}
                      onChange={(e) => setTanggalLahir(e.target.value)}
                    />
                    <br />
                    {errors.tanggalLahir && (
                      <small className="allValidasiError">
                        {errors.tanggalLahir.message}
                      </small>
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="field">
                <label className="label">Tempat Lahir</label>
                <div className="control">
                  <input
                    type="text"
                    // className="input"
                    // disabled
                    className={`input ${errors.tempatLahir && "invalid"}`}
                    {...register("tempatLahir", {
                      required: {
                        value: true,
                        message: "Tempat Lahir Wajib Di isi!",
                      },
                      minLength: {
                        value: 5,
                        message: "minimal 5 karakter",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("tempatLahir");
                    }}
                    placeholder="Tempat Lahir"
                    value={tempatLahir}
                    onChange={(e) => setTempatLahir(e.target.value)}
                  />
                  {errors.tempatLahir && (
                    <small className="allValidasiError">
                      {errors.tempatLahir.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Alamat</label>
                <div className="control">
                  <textarea

                    className={`textarea ${errors.alamat && "invalid"}`}
                    {...register("Alamat", {
                      required: {
                        value: true,
                        message: "Alamat Wajib Di isi!",
                      },
                      minLength: {
                        value: 5,
                        message: "Minimal 5 karakter",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("alamat");
                    }}
                    placeholder="Alamat"
                    value={Alamat}
                    onChange={(e) => setAlamat(e.target.value)}
                  />
                  {errors.alamat && (
                    <small className="allValidasiError">
                      {errors.Alamat.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Nama Ayah</label>
                <div className="control">
                  <textarea

                    className={`textarea ${errors.NamaAyah && "invalid"}`}
                    {...register("NamaAyah", {
                      required: {
                        value: true,
                        message: "Nama Ayah Wajib Di isi!",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("NamaAyah");
                    }}
                    placeholder="NamaAyah"
                    value={NamaAyah}
                    onChange={(e) => setNamaAyah(e.target.value)}
                  />
                  {errors.NamaAyah && (
                    <small className="allValidasiError">
                      {errors.NamaAyah.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Nama Ibu</label>
                <div className="control">
                  <textarea

                    className={`textarea ${errors.NamaIbu && "invalid"}`}
                    {...register("NamaIbu", {
                      required: {
                        value: true,
                        message: "Nama Ibu Wajib Di isi!",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("NamaIbu");
                    }}
                    placeholder="NamaIbu"
                    value={NamaIbu}
                    onChange={(e) => setNamaIbu(e.target.value)}
                  />
                  {errors.NamaIbu && (
                    <small className="allValidasiError">
                      {errors.NamaIbu.message}
                    </small>
                  )}
                </div>
              </div>


              {/* di hidden jika datanya sudah ada */}
              <div className="field">
                <label className="label">Tanggal Awal Masuk Al Hasanah(SK)</label>
                <div className="control">
                  <Form.Group controlId="duedate" className="dtp">
                    <Form.Control
                      type="date"
                      //  className="duedate"
                      className={`duedate ${errors.TanggalMasuk && "invalid"}`}
                      {...register("TanggalMasuk", {
                        required: {
                          value: true,
                          message: "Pilih Sesuai Tanggal Masuk!",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("TanggalMasuk");
                      }}
                      placeholder="Tanggal Masuk Al Hasanah"
                      value={TanggalMasuk}
                      onChange={(e) => setTanggalMasuk(e.target.value)}
                    // disabled
                    />
                    <br />
                    {errors.TanggalMasuk && (
                      <small className="allValidasiError">
                        {errors.TanggalMasuk.message}
                      </small>
                    )}
                  </Form.Group>
                </div>
              </div>

              {/* di hidden jika datanya sudah ada */}
              <div className="field">
                <label className="label">Tanggal Habis Kontrak(SK)</label>
                <div className="control">
                  <Form.Group controlId="duedate" className="dtp">
                    <Form.Control
                      type="date"
                      //  className="duedate"
                      className={`duedate ${errors.TanggalMasaKontrak && "invalid"}`}
                      {...register("TanggalMasaKontrak", {
                        required: {
                          value: true,
                          message: "Pilih Sesuai Tanggal Masa Kontrak!",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("TanggalMasaKontrak");
                      }}
                      placeholder="Tanggal Masa Kontrak"
                      value={TanggalMasaKontrak}
                      onChange={(e) => setTanggalMasaKontrak(e.target.value)}
                    // disabled
                    />
                    <br />
                    {errors.TanggalMasaKontrak && (
                      <small className="allValidasiError">
                        {errors.TanggalMasaKontrak.message}
                      </small>
                    )}
                  </Form.Group>
                </div>
              </div>


              <div className="field">
                <label className="label">Jabatan</label>
                <div className="control">
                  <input
                    type="text"
                    //  className="input"
                    //  disabled

                    className={`input ${errors.Jabatan && "invalid"}`}
                    {...register("Jabatan", {
                      required: {
                        value: true,
                        message: "Jabatan Wajib Di isi!",
                      },
                      minLength: {
                        value: 3,
                        message: "minimal 3 karakter",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("Jabatan");
                    }}
                    placeholder="Jabatan"
                    value={Jabatan}
                    onChange={(e) => setJabatan(e.target.value)}
                  />
                  {errors.Jabatan && (
                    <small className="allValidasiError">
                      {errors.Jabatan.message}
                    </small>
                  )}
                </div>
              </div>


              <div className="field">
                <label className="label">NPWP</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    // disabled
                    placeholder="Nomor Induk Kependudukan"
                    value={NPWP}
                    onChange={(e) => setNPWP(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">BPJSKES</label>
                <div className="control">
                  <input
                    type="number"
                    // className="input"
                    // disabled
                    className={`input ${errors.BPJS && "invalid"}`}
                    {...register("BPJSKES", {
                      required: {
                        value: true,
                        message: "Nomor BPJS Wajib Di isi!",
                      },
                      minLength: {
                        value: 13,
                        message: "Harus 13 angka",
                      },
                      maxLength: {
                        value: 13,
                        message: "Harus 13 angka",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("BPJSKES");
                    }}
                    placeholder="BPJSKES"
                    value={BPJS}
                    onChange={(e) => setBPJS(e.target.value)}
                  />
                  {errors.BPJSKES && (
                    <small className="allValidasiError">
                      {errors.BPJSKES.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">BPJSTK</label>
                <div className="control">
                  <input
                    type="number"
                    className={`input ${errors.BPJSTK && "invalid"}`}
                    {...register("BPJSTK", {
                      required: {
                        value: true,
                        message: "Nomor BPJSTK Wajib Di isi!",
                      },
                      minLength: {
                        value: 11,
                        message: "Harus 11 angka",
                      },
                      maxLength: {
                        value: 11,
                        message: "Harus 11 angka",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("BPJSTK");
                    }}
                    // className="input"
                    // disabled
                    placeholder="BPJSTK"
                    value={BPJSTK}
                    onChange={(e) => setBPJSTK(e.target.value)}
                  />
                  {errors.BPJSTK && (
                    <small className="allValidasiError">
                      {errors.BPJSTK.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Pendidikan Terakhir</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      // disabled
                      value={PendidikanTerakhir}
                      onChange={(e) => setPendidikanTerakhir(e.target.value)}
                    // required

                    >
                      <option>--Pilih--</option>
                      <option>SMP</option>
                      <option>SMA</option>
                      <option>D1</option>
                      <option>D2</option>
                      <option>D3</option>
                      <option>D4</option>
                      <option>S1</option>
                      <option>S1/Profesi</option>
                      <option>S1</option>
                      <option>S2</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  <input
                    type={InputType}
                    className="input"
                    // disabled
                    placeholder="*****"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="password-toogle-icon">{Icon}</span>
                </div>
              </div>

              <div className="field">
                <label className="label">Confirm Password</label>
                <div className="control">
                  <input
                    type={InputType}
                    // disabled
                    className="input"
                    placeholder="*****"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                  />

                  <span className="password-toogle-icon">{Icon}</span>
                </div>
              </div>

              <div className="field">
                <label className="label">Telegram</label>
                <div className="control">
                  <input
                    type="text"
                    // className="input"
                    // disabled
                    className={`input ${errors.telegram && "invalid"}`}
                    {...register("telegram", {
                      required: {
                        value: true,
                        message: "telegram Wajib Di isi!",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("telegram");
                    }}
                    placeholder="telegram"
                    value={telegram}
                    onChange={(e) => setTelegram(e.target.value)}
                  />
                  {errors.telegram && (
                    <small className="allValidasiError">
                      {errors.telegram.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Facebook</label>
                <div className="control">
                  <input
                    type="text"
                    // disabled
                    // className="input"

                    className={`input ${errors.facebook && "invalid"}`}
                    {...register("facebook", {
                      required: {
                        value: true,
                        message: "facebook Wajib Di isi!",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("facebook");
                    }}
                    placeholder="facebook"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                  />
                  {errors.facebook && (
                    <small className="allValidasiError">
                      {errors.facebook.message}
                    </small>
                  )}
                </div>
              </div>
              {/* 
              <div className="field">
                <label className="label">Instagram</label>
                <div className="control">
                  <input
                    // disabled
                    type="text"
                    // className="input"
                    className={`input ${errors.instagram && "invalid"}`}
                    {...register("instagram", {
                      required: {
                        value: true,
                        message: "instagram Wajib Di isi!",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("instagram");
                    }}
                    placeholder="instagram"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                  {errors.instagram && (
                    <small className="allValidasiError">
                      {errors.instagram.message}
                    </small>
                  )}
                </div>
              </div> */}

              <div className="field">
                <label className="label">Instagram</label>
                <div className="control">
                  <input
                    type="text"
                    className={`input ${errors.instagram && "invalid"}`}
                    {...register("instagram", {
                      required: {
                        value: true,
                        message: "instagram Wajib Di isi!",
                      },
                    })}
                    placeholder="instagram"
                    defaultValue={instagram} // Nilai awal
                    value={instagram} // Sinkron dengan state
                    onChange={(e) => {
                      setInstagram(e.target.value); // Perbarui state
                      trigger("instagram"); // Validasi ulang
                    }}
                  />
                  {errors.instagram && (
                    <small className="allValidasiError">
                      {errors.instagram.message}
                    </small>
                  )}
                </div>
              </div>



              <div className="field">
                <label className="label">Tiktok</label>
                <div className="control">
                  <input
                    // disabled
                    type="text"
                    className="input"
                    placeholder="tiktok"
                    value={tiktok}
                    onChange={(e) => setTiktok(e.target.value)}
                  />
                </div>
              </div>
              <br />

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataPersonList;
