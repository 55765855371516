import React, { useState } from "react";
import { useEffect } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import Url from "../global";
import "./globalList.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import * as FaIcons from "react-icons/fa";


const DataPersonListPegawai = () => {
  const [pegawais, setPegawais] = useState([]);
  const { pegawai } = useSelector((state) => state.auth);
  const [query, setQuery] = useState("");
  const [query2, setQuery2] = useState("");
  const [order, setOrder] = useState("ASC");

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...pegawais].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setPegawais(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...pegawais].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setPegawais(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    getPegawais();
  }, []);

  const getPegawais = async () => {
    const response = await axios.get(`${Url}/pegawai`);
    setPegawais(response.data);
  };

  return (
    <div>
      <h1 className="title">Pegawai</h1>
      <h2 className="subtitle">List Of Pegawai</h2>
      <br />

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="Table Pegawai List"
        sheet="tablexls"
        buttonText="Download as XLS"
      />
      <br />
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU") && <label> Masukan Nama </label>}
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU") && (
          <input
            className="search"
            placeholder="Cari Nama Individu"
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
        )}
      {pegawai && (pegawai.strRole === "admin" || pegawai.strRole === "kabid kepegawaian") && (
        <label className="label-unit">Masukan Unit </label>
      )}

      {pegawai && (pegawai.strRole === "admin" || pegawai.strRole === "kabid kepegawaian") && (
        <input
          className="search"
          placeholder="Cari Berdasarkan Unit"
          onChange={(e) => setQuery2(e.target.value.toLowerCase())}
        />
      )}
      <br />
      <br />

      <div className="table__wrapper_Scroll_Log">
        <table
          className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
          id="table-to-xls"
        >
          <thead className="flat">
            <tr>
              <th className="has-text-centered">No</th>
              <th
                className="has-text-centered"
                onClick={() => sorting("strNama")}
              >
                {" "}
                Nama <FaIcons.FaSort />
              </th>
              <th className="has-text-centered">NIK</th>
              <th className="has-text-centered">NRY</th>
              <th className="has-text-centered">Nomor Telpon</th>
              <th className="has-text-centered">Unit</th>
              <th className="has-text-centered">Gender</th>
              <th className="has-text-centered">Email</th>
              <th className="has-text-centered">Tanggal Lahir</th>
              <th className="has-text-centered">Tempat Lahir</th>
              <th className="has-text-centered">Alamat</th>
              <th className="has-text-centered">Nama Ayah</th>
              <th className="has-text-centered">Nama Ibu</th>
              <th className="has-text-centered">Tanggal Masuk Al Hasanah</th>
              <th className="has-text-centered">Tanggal Habis Kontrak</th>
              <th className="has-text-centered">Jabatan</th>
              <th className="has-text-centered">NPWP</th>
              <th className="has-text-centered">BPJSKES</th>
              <th className="has-text-centered">BPJSTK</th>
              <th className="has-text-centered">Pendidikan Terakhir</th>
              {/* <th className="has-text-centered">Jumlah Jam Kerja</th> */}
              <th className="has-text-centered">Jenis Pegawai</th>
            </tr>
          </thead>

          {pegawai && (pegawai.strRole === "admin" || pegawai.strRole === "kabid kepegawaian") && (
            <tbody>
              {pegawais
                .filter(
                  (pegawai) =>
                    pegawai.strNama.toLowerCase().includes(query) &&
                    pegawai.strUnit.toLowerCase().includes(query2)
                )
                .map((pegawai, index) => (
                  <tr key={pegawai.uuid}>
                    <td className="has-text-centered">{index + 1}</td>
                    <td width="15px">{pegawai.strNama}</td>
                    <td>'{pegawai.strNIK}</td>
                    <td>'{pegawai.strNRY}</td>
                    <td>{pegawai.strTelpon}</td>
                    <td>{pegawai.strUnit}</td>
                    <td>{pegawai.strGender}</td>
                    <td>{pegawai.strEmail}</td>
                    <td>
                      {pegawai.dateTanggalLahir ? moment(pegawai.dateTanggalLahir).format("DD/MM/YYYY") : " "}
                    </td>


                    <td>{pegawai.strTempatLahir}</td>
                    <td>{pegawai.strAlamat}</td>
                    <td>{pegawai.strNamaAyah}</td>
                    <td>{pegawai.strNamaIbu}</td>
                    <td>
                      {pegawai.dateTanggalMasuk ? moment(pegawai.dateTanggalMasuk).format("DD/MM/YYYY") : " "}
                    </td>
                    <td>
                      {pegawai.dateMasaKontrak ? moment(pegawai.dateMasaKontrak).format("DD/MM/YYYY") : " "}
                    </td>

                    <td>{pegawai.strJabatan}</td>
                    <td>
                      {pegawai.strNPWP
                        ? pegawai.strNPWP.substring(0, 2) +
                        "." +
                        pegawai.strNPWP.substring(2, 5) +
                        "." +
                        pegawai.strNPWP.substring(5, 8) +
                        "-" +
                        pegawai.strNPWP.substring(8, 9) +
                        "." +
                        (pegawai.strNPWP.substring(9, 12) +
                          "." +
                          pegawai.strNPWP.substring(12, 15))
                        : ""}
                    </td>
                    <td>{pegawai.strBPJS}</td>
                    <td>{pegawai.strBPJSTK}</td>
                    <td>{pegawai.strPendidikanTerakhir}</td>
                    {/* <td>{pegawai.strJumlahJamKerja}</td> */}
                    <td>{pegawai.strJenisKaryawan}</td>
                  </tr>
                ))}
            </tbody>
          )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 1" && (
              <tbody>
                {pegawais
                  .filter((pegawai) =>
                    pegawai.strNama.toLowerCase().includes(query)
                  )
                  .map((pegawai, index) =>
                    pegawai.strUnit === "PAUD IT Al Hasanah 1" ? (
                      <tr key={pegawai.uuid}>
                        <td className="has-text-centered">{index + 1}</td>
                        <td width="15px">{pegawai.strNama}</td>
                        <td>'{pegawai.strNIK}</td>
                        <td>'{pegawai.strNRY}</td>
                        <td>{pegawai.strTelpon}</td>
                        <td>{pegawai.strUnit}</td>
                        <td>{pegawai.strGender}</td>
                        <td>{pegawai.strEmail}</td>
                        <td>
                          {pegawai.dateTanggalLahir ? moment(pegawai.dateTanggalLahir).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strTempatLahir}</td>
                        <td>{pegawai.strAlamat}</td>
                        <td>{pegawai.strNamaAyah}</td>
                        <td>{pegawai.strNamaIbu}</td>
                        <td>
                          {pegawai.dateTanggalMasuk ? moment(pegawai.dateTanggalMasuk).format("DD/MM/YYYY") : " "}
                        </td>
                        <td>
                          {pegawai.dateMasaKontrak ? moment(pegawai.dateMasaKontrak).format("DD/MM/YYYY") : " "}
                        </td>


                        <td>{pegawai.strJabatan}</td>
                        <td>
                          {pegawai.strNPWP
                            ? pegawai.strNPWP.substring(0, 2) +
                            "." +
                            pegawai.strNPWP.substring(2, 5) +
                            "." +
                            pegawai.strNPWP.substring(5, 8) +
                            "-" +
                            pegawai.strNPWP.substring(8, 9) +
                            "." +
                            (pegawai.strNPWP.substring(9, 12) +
                              "." +
                              pegawai.strNPWP.substring(12, 15))
                            : ""}
                        </td>
                        <td>{pegawai.strBPJS}</td>
                        <td>{pegawai.strBPJSTK}</td>
                        <td>{pegawai.strPendidikanTerakhir}</td>
                        <td>{pegawai.strJenisKaryawan}</td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 2" && (
              <tbody>
                {pegawais
                  .filter((pegawai) =>
                    pegawai.strNama.toLowerCase().includes(query)
                  )
                  .map((pegawai, index) =>
                    pegawai.strUnit === "PAUD IT Al Hasanah 2" ? (
                      <tr key={pegawai.uuid}>
                        <td className="has-text-centered">{index + 1}</td>
                        <td width="15px">{pegawai.strNama}</td>
                        <td>'{pegawai.strNIK}</td>
                        <td>'{pegawai.strNRY}</td>
                        <td>{pegawai.strTelpon}</td>
                        <td>{pegawai.strUnit}</td>
                        <td>{pegawai.strGender}</td>
                        <td>{pegawai.strEmail}</td>
                        <td>
                          {pegawai.dateTanggalLahir ? moment(pegawai.dateTanggalLahir).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strTempatLahir}</td>
                        <td>{pegawai.strAlamat}</td>
                        <td>{pegawai.strNamaAyah}</td>
                        <td>{pegawai.strNamaIbu}</td>
                        <td>
                          {pegawai.dateTanggalMasuk ? moment(pegawai.dateTanggalMasuk).format("DD/MM/YYYY") : " "}
                        </td>
                        <td>
                          {pegawai.dateMasaKontrak ? moment(pegawai.dateMasaKontrak).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strJabatan}</td>
                        <td>
                          {pegawai.strNPWP
                            ? pegawai.strNPWP.substring(0, 2) +
                            "." +
                            pegawai.strNPWP.substring(2, 5) +
                            "." +
                            pegawai.strNPWP.substring(5, 8) +
                            "-" +
                            pegawai.strNPWP.substring(8, 9) +
                            "." +
                            (pegawai.strNPWP.substring(9, 12) +
                              "." +
                              pegawai.strNPWP.substring(12, 15))
                            : ""}
                        </td>
                        <td>{pegawai.strBPJS}</td>
                        <td>{pegawai.strBPJSTK}</td>
                        <td>{pegawai.strPendidikanTerakhir}</td>
                        <td>{pegawai.strJenisKaryawan}</td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SDIT Al Hasanah 1" && (
              <tbody>
                {pegawais
                  .filter((pegawai) =>
                    pegawai.strNama.toLowerCase().includes(query)
                  )
                  .map((pegawai, no) =>
                    pegawai.strUnit === "SDIT Al Hasanah 1" ? (
                      <tr key={pegawai.uuid}>
                        <td className="has-text-centered">{no + 1}</td>
                        <td width="15px">{pegawai.strNama}</td>
                        <td>'{pegawai.strNIK}</td>
                        <td>'{pegawai.strNRY}</td>
                        <td>{pegawai.strTelpon}</td>
                        <td>{pegawai.strUnit}</td>
                        <td>{pegawai.strGender}</td>
                        <td>{pegawai.strEmail}</td>
                        <td>
                          {pegawai.dateTanggalLahir ? moment(pegawai.dateTanggalLahir).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strTempatLahir}</td>
                        <td>{pegawai.strAlamat}</td>
                        <td>{pegawai.strNamaAyah}</td>
                        <td>{pegawai.strNamaIbu}</td>
                        <td>
                          {pegawai.dateTanggalMasuk ? moment(pegawai.dateTanggalMasuk).format("DD/MM/YYYY") : " "}
                        </td>
                        <td>
                          {pegawai.dateMasaKontrak ? moment(pegawai.dateMasaKontrak).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strJabatan}</td>
                        <td>
                          {pegawai.strNPWP
                            ? pegawai.strNPWP.substring(0, 2) +
                            "." +
                            pegawai.strNPWP.substring(2, 5) +
                            "." +
                            pegawai.strNPWP.substring(5, 8) +
                            "-" +
                            pegawai.strNPWP.substring(8, 9) +
                            "." +
                            (pegawai.strNPWP.substring(9, 12) +
                              "." +
                              pegawai.strNPWP.substring(12, 15))
                            : ""}
                        </td>
                        <td>{pegawai.strBPJS}</td>
                        <td>{pegawai.strBPJSTK}</td>
                        <td>{pegawai.strPendidikanTerakhir}</td>
                        <td>{pegawai.strJenisKaryawan}</td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SDIT Al Hasanah 2" && (
              <tbody>
                {pegawais
                  .filter((pegawai) =>
                    pegawai.strNama.toLowerCase().includes(query)
                  )
                  .map((pegawai, index) =>
                    pegawai.strUnit === "SDIT Al Hasanah 2" ? (
                      <tr key={pegawai.uuid}>
                        <td className="has-text-centered">{index + 1}</td>
                        <td width="15px">{pegawai.strNama}</td>
                        <td>'{pegawai.strNIK}</td>
                        <td>'{pegawai.strNRY}</td>
                        <td>{pegawai.strTelpon}</td>
                        <td>{pegawai.strUnit}</td>
                        <td>{pegawai.strGender}</td>
                        <td>{pegawai.strEmail}</td>
                        <td>
                          {pegawai.dateTanggalLahir ? moment(pegawai.dateTanggalLahir).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strTempatLahir}</td>
                        <td>{pegawai.strAlamat}</td>
                        <td>{pegawai.strNamaAyah}</td>
                        <td>{pegawai.strNamaIbu}</td>
                        <td>
                          {pegawai.dateTanggalMasuk ? moment(pegawai.dateTanggalMasuk).format("DD/MM/YYYY") : " "}
                        </td>
                        <td>
                          {pegawai.dateMasaKontrak ? moment(pegawai.dateMasaKontrak).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strJabatan}</td>
                        <td>
                          {pegawai.strNPWP
                            ? pegawai.strNPWP.substring(0, 2) +
                            "." +
                            pegawai.strNPWP.substring(2, 5) +
                            "." +
                            pegawai.strNPWP.substring(5, 8) +
                            "-" +
                            pegawai.strNPWP.substring(8, 9) +
                            "." +
                            (pegawai.strNPWP.substring(9, 12) +
                              "." +
                              pegawai.strNPWP.substring(12, 15))
                            : ""}
                        </td>
                        <td>{pegawai.strBPJS}</td>
                        <td>{pegawai.strBPJSTK}</td>
                        <td>{pegawai.strPendidikanTerakhir}</td>
                        <td>{pegawai.strJenisKaryawan}</td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SMPI Al Hasanah" && (
              <tbody>
                {pegawais
                  .filter((pegawai) =>
                    pegawai.strNama.toLowerCase().includes(query)
                  )
                  .map((pegawai, index) =>
                    pegawai.strUnit === "SMPI Al Hasanah" ? (
                      <tr key={pegawai.uuid}>
                        <td className="has-text-centered">{index + 1}</td>
                        <td width="15px">{pegawai.strNama}</td>
                        <td>'{pegawai.strNIK}</td>
                        <td>'{pegawai.strNRY}</td>
                        <td>{pegawai.strTelpon}</td>
                        <td>{pegawai.strUnit}</td>
                        <td>{pegawai.strGender}</td>
                        <td>{pegawai.strEmail}</td>
                        <td>
                          {pegawai.dateTanggalLahir ? moment(pegawai.dateTanggalLahir).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strTempatLahir}</td>
                        <td>{pegawai.strAlamat}</td>
                        <td>{pegawai.strNamaAyah}</td>
                        <td>{pegawai.strNamaIbu}</td>
                        <td>
                          {pegawai.dateTanggalMasuk ? moment(pegawai.dateTanggalMasuk).format("DD/MM/YYYY") : " "}
                        </td>
                        <td>
                          {pegawai.dateMasaKontrak ? moment(pegawai.dateMasaKontrak).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strJabatan}</td>
                        <td>
                          {pegawai.strNPWP
                            ? pegawai.strNPWP.substring(0, 2) +
                            "." +
                            pegawai.strNPWP.substring(2, 5) +
                            "." +
                            pegawai.strNPWP.substring(5, 8) +
                            "-" +
                            pegawai.strNPWP.substring(8, 9) +
                            "." +
                            (pegawai.strNPWP.substring(9, 12) +
                              "." +
                              pegawai.strNPWP.substring(12, 15))
                            : ""}
                        </td>
                        <td>{pegawai.strBPJS}</td>
                        <td>{pegawai.strBPJSTK}</td>
                        <td>{pegawai.strPendidikanTerakhir}</td>
                        <td>{pegawai.strJenisKaryawan}</td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SMAIT Al Hasanah" && (
              <tbody>
                {pegawais
                  .filter((pegawai) =>
                    pegawai.strNama.toLowerCase().includes(query)
                  )
                  .map((pegawai, index) => (
                    <tr key={pegawai.uuid}>
                      <td className="has-text-centered">{index + 1}</td>
                      <td width="15px">{pegawai.strNama}</td>
                      <td>'{pegawai.strNIK}</td>
                      <td>'{pegawai.strNRY}</td>
                      <td>{pegawai.strTelpon}</td>
                      <td>{pegawai.strUnit}</td>
                      <td>{pegawai.strGender}</td>
                      <td>{pegawai.strEmail}</td>
                      <td>
                        {pegawai.dateTanggalLahir ? moment(pegawai.dateTanggalLahir).format("DD/MM/YYYY") : " "}
                      </td>

                      <td>{pegawai.strTempatLahir}</td>
                      <td>{pegawai.strAlamat}</td>
                      <td>{pegawai.strNamaAyah}</td>
                      <td>{pegawai.strNamaIbu}</td>
                      <td>
                        {pegawai.dateTanggalMasuk ? moment(pegawai.dateTanggalMasuk).format("DD/MM/YYYY") : " "}
                      </td>
                      <td>
                        {pegawai.dateMasaKontrak ? moment(pegawai.dateMasaKontrak).format("DD/MM/YYYY") : " "}
                      </td>

                      <td>{pegawai.strJabatan}</td>
                      <td>
                        {pegawai.strNPWP
                          ? pegawai.strNPWP.substring(0, 2) +
                          "." +
                          pegawai.strNPWP.substring(2, 5) +
                          "." +
                          pegawai.strNPWP.substring(5, 8) +
                          "-" +
                          pegawai.strNPWP.substring(8, 9) +
                          "." +
                          (pegawai.strNPWP.substring(9, 12) +
                            "." +
                            pegawai.strNPWP.substring(12, 15))
                          : ""}
                      </td>
                      <td>{pegawai.strBPJS}</td>
                      <td>{pegawai.strBPJSTK}</td>
                      <td>{pegawai.strPendidikanTerakhir}</td>
                      <td>{pegawai.strJenisKaryawan}</td>
                    </tr>
                  ))}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "Pondok Pesantren" && (
              <tbody>
                {pegawais
                  .filter((pegawai) =>
                    pegawai.strNama.toLowerCase().includes(query)
                  )
                  .map((pegawai, index) =>
                    pegawai.strUnit === "Pondok Pesantren" ? (
                      <tr key={pegawai.uuid}>
                        <td className="has-text-centered">{index + 1}</td>
                        <td width="15px">{pegawai.strNama}</td>
                        <td>'{pegawai.strNIK}</td>
                        <td>'{pegawai.strNRY}</td>
                        <td>{pegawai.strTelpon}</td>
                        <td>{pegawai.strUnit}</td>
                        <td>{pegawai.strGender}</td>
                        <td>{pegawai.strEmail}</td>
                        <td>
                          {pegawai.dateTanggalLahir ? moment(pegawai.dateTanggalLahir).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strTempatLahir}</td>
                        <td>{pegawai.strAlamat}</td>
                        <td>{pegawai.strNamaAyah}</td>
                        <td>{pegawai.strNamaIbu}</td>
                        <td>
                          {pegawai.dateTanggalMasuk ? moment(pegawai.dateTanggalMasuk).format("DD/MM/YYYY") : " "}
                        </td>
                        <td>
                          {pegawai.dateMasaKontrak ? moment(pegawai.dateMasaKontrak).format("DD/MM/YYYY") : " "}
                        </td>

                        <td>{pegawai.strJabatan}</td>
                        <td>
                          {pegawai.strNPWP
                            ? pegawai.strNPWP.substring(0, 2) +
                            "." +
                            pegawai.strNPWP.substring(2, 5) +
                            "." +
                            pegawai.strNPWP.substring(5, 8) +
                            "-" +
                            pegawai.strNPWP.substring(8, 9) +
                            "." +
                            (pegawai.strNPWP.substring(9, 12) +
                              "." +
                              pegawai.strNPWP.substring(12, 15))
                            : ""}
                        </td>
                        <td>{pegawai.strBPJS}</td>
                        <td>{pegawai.strBPJSTK}</td>
                        <td>{pegawai.strPendidikanTerakhir}</td>
                        <td>{pegawai.strJenisKaryawan}</td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}


        </table>
      </div>
    </div>
  );
};

export default DataPersonListPegawai;
